* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kalpurush", "Roboto";
}
@media only screen and (min-width: 1024px) {
  html {
    font-size: 80%;
  }
}
@media only screen and (min-width: 1280px) {
  html {
    font-size: 105%;
  }
}
@media only screen and (min-width: 1536px) {
  html {
    font-size: 115%;
  }
}
@media only screen and (min-width: 1920px) {
  html {
    font-size: 135.5%;
  }
}
